/* body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
} */

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body {
    margin: 0;
    color: #181e2d;
    /* background-color: #fff; */
    font-family: 'Poppins', sans-serif;
}

/* #192841
 #3a5c96
 #5a91eb
 #f0f9ff;
 #505355
 #041a2b*/

/* #181e2d */
/* #455782 */
/* #738fd7 */
/* https://imagecolorpicker.com/color-code/181e2d */
